import React, { FC, useEffect, useState } from 'react';
import { Box, Divider, MenuItem, Select, Typography } from '@mui/material';
import { useStyles } from './InviteCustomersTab.styles';
import { ElementsStrings } from '../../../../../../common/localization/en';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { ImportFileModal } from '../../../../../rewards/CampaignSummary/Modals/ImportFileModal';
import { useModal } from '../../../../../../hooks/useModal';
import { AddIndividualCustomerModal } from '../../../../../rewards/CampaignSummary/Modals/AddIndividualCustomerModal';
import { QrCodeType, VenueProperties } from '../../../../../../api/models/venue';
import { ExpandIconGray } from '../../../../../../common/assets/ExpandIconGray';
import { QRCode } from '../../../../../../common/components/QrCode/QRCode';
import { useWebAppLink } from '../../../../../../hooks/useWebAppLink';
import { InvitesBlockCopyUrl } from '../../../../../../common/components/InviteBlokCopyUrl/InvitesBlockCopyUrl';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import InviteCreatorModal from '../../../../../../common/components/InviteCreatorModal/InviteCreatorModal';
import { AddAndUploadModal } from '../../../../../rewards/CampaignSummary/Modals/AddAndUploadModal/AddAndUploadModal';
import {
  getCampaignCountData,
  getCampaignInvitations,
  getInvitesCounts,
  setOpenAddCustomersModalTrigger,
} from '../../../../../../store/slices/campaignsSlice';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { useParams } from 'react-router-dom';
import { isUWMClient } from '../../../../../../utils/isUWMClient';
import { useQuickStartLaunchPad } from '../../../../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { useSendInvitesTab } from '../SendInvitesTab/useSendInvitesTab';
import { SEND_INVITES_TIMEOUT_SECONDS_TEST } from '../SendInvitesTab/SendInvitesTab';
import { differenceInSeconds } from 'date-fns';
import { useVenueProperty } from '../../../../../../hooks/useVenueProperty';
import { setElementsActiveTabIndex } from '../../../../../../store/slices/uiSlice';
import IconButton from '@mui/material/IconButton';
import { OpenUrlIcon } from '../../../../../../common/assets/OpenUrlIcon';
import { Tooltip } from '../../../../../../common/components/Tooltip/Tooltip';
import { useInvitesQueueStats } from '../../useInvitesQueueStats';
import { SkeletonLoader } from '../../../../../../common/components/SkeletonLoader/SkeletonLoader';

export const InviteCustomersTab: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { id: campaignId } = useParams<{ id: string }>();

  const { currentCampaign, openAddCustomerModalTrigger } = useTypedSelector(
    (state) => state.campaigns,
  );
  const [qrCodeTypeSelected, setQrCodeTypeSelected] = useState(QrCodeType.Branded);
  const { webAppLink } = useWebAppLink({ queryParams: { cid: currentCampaign?.shortcode! } });
  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();
  const isUwmClient = isUWMClient();
  const { isStarted: isQsStarted } = useQuickStartLaunchPad();

  const {
    venue: { logo },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const isBranded = qrCodeTypeSelected === QrCodeType.Branded;
  const brandColor = isBranded ? venueBrandColor : '#000';

  const venueLogo = venueProperties?.['dashboard.QRCode.config']?.logo || logo;
  const imageUrl = isBranded ? venueLogo : '';
  const [activeInviteOption, setActiveInviteOption] = useState(0);
  const [addedUserName, setAddedUserName] = useState('');
  const [uploadVideoOption, setUploadVideoOption] = useState('No');
  const { isOpen: isUploadModalOpen, open: openUploadModal, close: closeUploadModal } = useModal(
    false,
  );

  const {
    canCreateInvites,
    canNotCreate,
    canCreateAndNoUnsent,
    canCreateAndUnsent,
    unsentInvites,
    isLoading,
    dateDifference,
    isUnSentInvitesOverLimit
  } = useInvitesQueueStats();

  const closeUploadModalHandler = () => {
    closeUploadModal();
    setAddedUserName('');
  };

  const {
    isOpen: isImportFileModalOpen,
    open: openImportFileModal,
    close: closeImportFileModal,
  } = useModal(false);

  const {
    isOpen: isAddCustomerModalOpen,
    open: openAddCustomerModal,
    close: closeAddCustomerModal,
  } = useModal(false);

  const {
    isOpen: isInviteCreatorModalOpen,
    open: openInviteCreatorModal,
    close: closeInviteCreatorModal,
  } = useModal(false);

  const handleInviteCreatorNextStep = () => {
    closeInviteCreatorModal();
    openAddCustomerModal();
  };

  useEffect(() => {
    if (openAddCustomerModalTrigger) {
      openInviteCreatorModal();
      dispatch(setOpenAddCustomersModalTrigger(false));
    }
  }, [dispatch, openAddCustomerModalTrigger, openInviteCreatorModal]);

  const { lastSendDate } = useSendInvitesTab();

  const isSendTimeoutPassed = lastSendDate
    ? differenceInSeconds(new Date(), lastSendDate) >= SEND_INVITES_TIMEOUT_SECONDS_TEST
    : true;

  const { value: inviteStatsViewed, update } = useVenueProperty<
    VenueProperties['dashboard.invite-stats-viewed']
  >({
    property: 'dashboard.invite-stats-viewed',
  });

  const getAddCustomersTitle = () => {
    if (canCreateAndNoUnsent) {
      return ElementsStrings.SendTextMessage;
    }
    if (canCreateAndUnsent) {
      return `${unsentInvites} ${ElementsStrings.PreperedInvites}`;
    }
    if (canNotCreate) {
      if (unsentInvites) {
        return `${unsentInvites} ${ElementsStrings.PreperedInvites}`;
      } else return ElementsStrings.InvitesEvaluation;
    }
  };

  const setInvitesViewed = () => {
    if (inviteStatsViewed) {
      update([...inviteStatsViewed, currentCampaign?.id]);
    } else {
      update([currentCampaign?.id]);
    }
  };

  const isSendInvitesButtonVisible = !!unsentInvites || canNotCreate;

  return (
    <Box className={classes.container}>
      <ImportFileModal
        campaignId={currentCampaign?.id!}
        isOpen={isImportFileModalOpen}
        onClose={closeImportFileModal}
        onFinish={() => {
          dispatch(setElementsActiveTabIndex(1));
          dispatch(getInvitesCounts({ campaignId }));
        }}
      />
      {currentCampaign && (
        <InviteCreatorModal
          isOpen={isInviteCreatorModalOpen}
          onClose={() => {
            closeInviteCreatorModal();
            setActiveInviteOption(0);
          }}
          onNextStep={handleInviteCreatorNextStep}
          campaigns={[]}
          selectedCampaign={currentCampaign}
          onCampaignSelect={() => {}}
          activeInviteOption={activeInviteOption}
          onInviteOptionChange={(index: number) => setActiveInviteOption(index)}
          isCampaignModal={true}
        />
      )}
      <AddIndividualCustomerModal
        campaignId={currentCampaign?.id!}
        isOpen={isAddCustomerModalOpen}
        onClose={() => {
          closeAddCustomerModal();
          setUploadVideoOption('No');
          if (uploadVideoOption === 'Yes') {
            openUploadModal();
          }
        }}
        uploadVideoOption={uploadVideoOption}
        onUploadVideoOptionChange={(value) => {
          setUploadVideoOption(value);
        }}
        onCustomerAdded={(name) => {
          setAddedUserName(name);
          dispatch(getCampaignCountData({ campaignId }));
          dispatch(getCampaignInvitations({ campaignId }));
          dispatch(getInvitesCounts({ campaignId }));
        }}
        withInvite={activeInviteOption === 0}
      />
      {currentCampaign && addedUserName && (
        <AddAndUploadModal
          campaignId={currentCampaign.id}
          isOpen={isUploadModalOpen}
          onClose={closeUploadModalHandler}
          name={addedUserName}
          selectedCampaign={currentCampaign}
          onUploaded={() => {
            dispatch(getCampaignCountData({ campaignId }));
          }}
        />
      )}
      <Box
        className={classes.sendMessageWrapper}
        style={{ background: isUwmClient ? 'rgb(195, 216, 229)' : '#EBE9FF' }}
      >
        <Box className={classes.rowWrapper}>
          <SkeletonLoader height={35} width={700} isLoading={isLoading}>
            <Typography className={classes.title}>{getAddCustomersTitle()}</Typography>
          </SkeletonLoader>

          {isSendInvitesButtonVisible && (
            <SkeletonLoader height={35} width={126} isLoading={isLoading}>
              <RoundedButton
                className={classes.uploadList}
                title={ElementsStrings.GoToSendInvites}
                onClick={() => {
                  dispatch(setElementsActiveTabIndex(5));
                  if (isSendTimeoutPassed) {
                    setInvitesViewed();
                  }
                  trackEvent(
                    RewardCampaignsEvents.RewardCampaignSummaryPageGoToSendInvitesTabClick,
                    {
                      campaignId,
                    },
                  );
                }}
                sx={{
                  '&:disabled': {
                    backgroundColor: '#5242EA',
                  },
                }}
              />
            </SkeletonLoader>
          )}
        </Box>
        {isSendInvitesButtonVisible && <Divider className={classes.divider} color="#D9D9D9" />}
        <Box className={classes.rowWrapper}>
          <SkeletonLoader height={35} width={300} isLoading={isLoading}>
            {canCreateInvites || isUnSentInvitesOverLimit ? (
              <Typography className={classes.subtitle}>
                {isHealthCare ? ElementsStrings.SendPatients : ElementsStrings.SendCustomers}
                <Typography display="inline" className={classes.linkText}>
                  {ElementsStrings.PersonalizedLink}
                </Typography>
                {ElementsStrings.ToQuicklyCreate}
              </Typography>
            ) : (
              <Typography className={classes.subtitle}>
                {ElementsStrings.AddCustomersUnavailableSubtitle + dateDifference}
              </Typography>
            )}
          </SkeletonLoader>

          <Box className={classes.buttonsWrapper}>
            {canCreateInvites && (
              <SkeletonLoader height={35} width={126} isLoading={isLoading}>
                <RoundedButton
                  className={classes.uploadList}
                  title={ElementsStrings.UploadAList}
                  onClick={() => {
                    openImportFileModal();
                    trackEvent(
                      RewardCampaignsEvents.RewardCampaignSummaryPageImportDataButtonClick,
                      {
                        campaignId,
                      },
                    );
                  }}
                  sx={{
                    '&:disabled': {
                      backgroundColor: '#5242EA',
                    },
                  }}
                />
              </SkeletonLoader>
            )}
            {canCreateInvites && (
              <SkeletonLoader height={35} width={126} isLoading={isLoading}>
                <RoundedButton
                  className={classes.inviteButton}
                  title={isHealthCare ? ElementsStrings.AddAPatient : ElementsStrings.AddIndividual}
                  outlined={true}
                  onClick={() => {
                    openAddCustomerModal();
                    trackEvent(
                      RewardCampaignsEvents.RewardCampaignSummaryImportManuallyButtonClick,
                      {
                        campaignId,
                      },
                    );
                  }}
                  sx={{
                    '&:disabled': {
                      backgroundColor: '#FFF',
                    },
                  }}
                />
              </SkeletonLoader>
            )}
          </Box>
        </Box>
      </Box>

      <Box className={classes.qrSectionWrapper}>
        <Box className={classes.qrLeftSectionWrapper}>
          <Typography className={classes.title}>{ElementsStrings.PrintCode}</Typography>
          <Typography className={classes.subtitle} width="320px">
            {isHealthCare ? ElementsStrings.AllowPatiens : ElementsStrings.AllowCustomers}
          </Typography>

          <Typography className={classes.selectedStyleText}>
            {ElementsStrings.SelectedStyle}
          </Typography>

          <Select
            displayEmpty={true}
            renderValue={() => {
              return (
                <Typography className={classes.brandSelectText}>
                  {qrCodeTypeSelected.toLowerCase()}
                </Typography>
              );
            }}
            MenuProps={{
              className: classes.selectMenu,
              PaperProps: {
                className: classes.selectPaper,
              },
            }}
            className={classes.qrSelect}
            IconComponent={() => (
              <Box className={classes.expandIconWrapper}>
                <ExpandIconGray className={classes.expandIcon} />
              </Box>
            )}
            data-qs-button="false"
            disabled={isQsStarted}
          >
            {[QrCodeType.Branded, QrCodeType.Default].map((item, index) => {
              return (
                <MenuItem
                  onClick={() => {
                    setQrCodeTypeSelected(item);
                    trackEvent(RewardCampaignsEvents.SelectQrCodeType, {
                      value: item,
                    });
                  }}
                  className={classes.menuItem}
                  key={item}
                >
                  {item.toLowerCase()}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <Box className={classes.qrRightSectionWrapper}>
          <Typography className={classes.downloadText}>
            {ElementsStrings.ClickToDownload}
          </Typography>
          <Box className={classes.qrCodeWrapper}>
            <QRCode
              content={`${webAppLink}&qrcode=true`}
              color={brandColor}
              logo={imageUrl}
              withDownloadButton={false}
              size={118}
              padding="6px"
              qrMargin={0.1}
              logoBorderWidth="10px"
              downloadOnClick={!isQsStarted}
              withHover={true}
            />
          </Box>
        </Box>
      </Box>

      <Box className={classes.inviteLinkWrapper}>
        <Box className={classes.linkLeftSectionWrapper}>
          <Typography className={classes.title}>{ElementsStrings.InvitationLink}</Typography>
          <Typography className={classes.subtitle} width="320px">
            {ElementsStrings.IncludeInEmails}
          </Typography>
        </Box>
        <Box className={classes.linkRightSectionWrapper}>
          <InvitesBlockCopyUrl webAppLink={webAppLink} />
          <Tooltip
            title={ElementsStrings.ViewLive}
            icon={
              <IconButton
                className={classes.viewLiveButton}
                onClick={() => {
                  //@ts-ignore
                  window.open(webAppLink, '_blank').focus();
                }}
              >
                <OpenUrlIcon />
              </IconButton>
            }
            light={true}
          />
        </Box>
      </Box>
    </Box>
  );
};
